<template>
  <component :is="template"></component>
</template>
<script>

import { mapGetters } from "vuex";
import TupaList from "./tupaList";

export default {
  components: {
    TupaList,
  },
  data() {
    return {
      tupa: {
        estado : true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.tupa = this.tupa;
    } else {
      muta.oper = this.$route.query.oper;
    }
    
    this.$store.commit("gstupa/OPERACION_TUPA", muta);
  },

  computed: { ...mapGetters({ template: "gstupa/getTupaTpl" }) },
};
</script>
<style>
</style>